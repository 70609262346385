.c-btnCv {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
  height: 3.5rem;
  font-size: 2rem;
  font-weight: bold;
  background: #fff;
  border-radius: 0.8rem;

  @media (--mobile) {
    width: 14.8rem;

    // height: 5rem;
    font-size: 1.8rem;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: calc(100% + 0.8rem);
    height: calc(100% + 0.8rem);
    content: "";
    background: #000;
    background: linear-gradient(to left, #8ec9ff, #a9bbe3, #c498d5, #ff63c4);
    filter: blur(0);
    border-radius: 1rem;
    transition: 0.3s filter;
    transform: translate(-50%, -50%);
  }

  &:hover {
    &::after {
      filter: blur(1.5rem);
    }
  }
}

.c-btnCv02 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26rem;
  max-width: 100%;
  height: 4rem;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.6rem;
  color: #fff;
  background: #7638a0;
  border: 0.2rem solid #7638a0;
  border-radius: 999rem;
  transition: 0.3s;

  svg {
    position: absolute;
    top: 50%;
    right: 2rem;
    width: 1.5rem;
    transition: 0.3s;
    transform: translateY(-50%);

    * {
      transition: 0.3s;
    }
  }

  @media (--large) {
    &:hover {
      color: #7638a0;
      background: #fff;

      svg {
        * {
          fill: #7638a0;
        }
      }
    }
  }
}

.c-btn-pink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21rem;
  height: 3.5rem;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.05rem;
  background-color: #f24ab3;
  border-radius: 25rem;
  transition: 0.3s all;

  &:hover {
    color: #f24ab3;
    background-color: #fff;
    border: 0.2rem solid #f24ab3;
  }
}

.comingsoon {
  pointer-events: none;
}
