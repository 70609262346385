@keyframes ticker {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes ticker2 {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-200%);
  }
}

@keyframes ticker-reverse {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes ticker2-reverse {
  0% {
    transform: translateX(-200%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes anime {
  0% {
    transform: translateY(-150%);
  }

  3% {
    transform: translateY(-50%);
  }

  33% {
    transform: translateY(-50%);
  }

  36% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(50%);
  }
}
