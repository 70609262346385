.l-header {
  position: fixed;
  left: 50%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateX(-50%);

  @media (--large) {
    top: 3.7rem;
    width: calc(100% - 4rem);
    height: 6.5rem;
    padding: 0 2rem;
    background: unquote("rgb(255 255 255 / 0%)");
    border-radius: 999rem;
    transition: 0.5s;
  }

  @media (--mobile) {
    top: 4.5rem;
    width: calc(100% - 6rem);
    transition: 0.5s;
  }

  &.is-scroll {
    @media (--large) {
      top: 2rem;
      padding: 0 3rem;
      background: unquote("rgb(255 255 255 / 50%)");
      box-shadow: 0 0 9rem unquote("rgb(0 0 0 / 10%)");
    }
  }

  &__left {
    width: 18rem;

    @media (--mobile) {
      width: 14rem;
    }
  }

  &__drawer {
    position: relative;
    z-index: 4;
    width: 2.4rem;
    height: 1.6rem;

    span {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 2px;
      background: #000;
      border-radius: 999rem;
      transition: 0.3s;
      transform: translateY(-50%);

      &:first-of-type {
        top: 0;
      }

      &:nth-of-type(3) {
        top: inherit;
        bottom: 0;
        transform: translateY(50%);
      }

      @at-root .is-active & {
        &:first-of-type {
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
          transform: translateX(2rem);
        }

        &:nth-of-type(3) {
          bottom: 50%;
          transform: translateY(50%) rotate(-45deg);
        }
      }
    }
  }

  &__right {
    @media (--large) {
      display: flex;
      align-items: center;
    }

    @media (--mobile) {
      display: block !important;
    }
  }

  &__rightWrap {
    @media (--mobile) {
      position: fixed;
      top: -4.5rem;
      right: -3rem;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80vw;
      height: 100vh;
      background: #fff;
      transition: 0.3s;
      transform: translateX(100%);

      @at-root .is-active & {
        transform: translateX(0);
      }
    }
  }

  &__list {
    @media (--large) {
      display: flex;
      align-items: center;
    }
  }

  &__ele {
    &:nth-of-type(n + 2) {
      @media (--large) {
        margin-left: 3.2rem;
      }

      @media (--mobile) {
        margin-top: 3rem;
      }
    }

    a {
      display: inline-block;
      font-size: 1.4rem;
      letter-spacing: 0.05rem;
      transition: 0.3s;

      @media (--mobile) {
        font-size: 2.7rem;
      }

      @media (--large) {
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  &__cv {
    width: 14rem !important;
    height: 3.4rem !important;
    font-size: 1.4rem !important;
    font-weight: lighter !important;
    letter-spacing: 0.05rem;

    @media (--large) {
      margin-left: 3.5rem !important;
    }

    @media (--mobile) {
      width: 19.3rem !important;
      height: 5rem !important;
      margin-top: 4rem !important;
      font-size: 2.2rem !important;
    }
  }

  &__overlay {
    position: fixed;
    top: -4.5rem;
    right: -3rem;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background: unquote("rgb(160 124 173 / 90%)");
    opacity: 0;
    transition: 0.3s;

    @at-root .is-active & {
      pointer-events: inherit;
      opacity: 1;
    }
  }
}
