.l-footer {
  position: relative;
  z-index: 3;
  padding: 0 4rem 2rem;
  margin-top: 9rem;

  @media (--mobile) {
    padding: 0 3rem 3rem;
    margin-top: 8rem;
  }

  &__top {
    display: flex;
    justify-content: space-between;

    @media (--mobile) {
      display: block;
    }
  }

  &__logo {
    width: 18.3rem;
  }

  &__fList {
    display: flex;

    @media (--mobile) {
      display: block;
      margin-top: 5rem;
    }
  }

  &__fEl {
    &:nth-of-type(n + 2) {
      margin-left: 3.2rem;

      @media (--mobile) {
        margin-top: 1rem;
        margin-left: 0;
      }
    }
  }

  &__fLink {
    position: relative;
    font-size: 1.8rem;

    @media (--mobile) {
      position: relative;
      display: block;
      padding: 1rem 0;
    }

    @media (--large) {
      &::after {
        position: absolute;
        bottom: 0.2rem;
        left: 0;
        width: 100%;
        height: 0.1rem;
        margin: auto;
        content: "";
        background: #8e8e8e;
        transition: transform 0.3s;
        transform: scale(0, 1);
        transform-origin: right center;
      }

      &:hover {
        &::after {
          transform: scale(1, 1);
          transform-origin: left center;
        }
      }
    }
  }

  &__plus {
    position: absolute;
    top: 50%;
    right: 0;
    width: 2.5rem;
    height: 2.5rem;
    border: 0.1rem solid #000;
    border-radius: 999rem;
    transform: translateY(-50%);

    &::after,
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.1rem;
      height: 60%;
      content: "";
      background: #000;
      transition: 0.3s;
      transform: translate(-50%, -50%);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &.is-active {
      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &__sList {
    margin-top: 1rem;
    font-size: 1.3rem;

    @media (--mobile) {
      display: none;
    }
  }

  &__sEl {
    &:nth-of-type(n + 2) {
      margin-top: 1rem;
    }
  }

  &__sLink {
    position: relative;
    color: #8e8e8e;

    @media (--large) {
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1rem;
        margin: auto;
        content: "";
        background: #8e8e8e;
        transition: transform 0.3s;
        transform: scale(0, 1);
        transform-origin: right center;
      }

      &:hover {
        &::after {
          transform: scale(1, 1);
          transform-origin: left center;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 6.4rem;

    @media (--mobile) {
      flex-direction: column-reverse;
    }
  }

  &__list {
    display: flex;

    li {
      &:nth-of-type(n + 2) {
        padding-left: 2rem;
        margin-left: 2rem;
        border-left: 0.1rem solid #8e8e8e;

        @media (--mobile) {
          padding-left: 1rem;
          margin-left: 1rem;
        }
      }

      a {
        position: relative;
        display: inline-block;
        font-size: 1.4rem;
        color: #8e8e8e;

        @media (--large) {
          &::after {
            position: absolute;
            bottom: -0.2rem;
            left: 0;
            width: 100%;
            height: 0.1rem;
            margin: auto;
            content: "";
            background: #8e8e8e;
            transition: transform 0.3s;
            transform: scale(0, 1);
            transform-origin: right center;
          }

          &:hover {
            &::after {
              transform: scale(1, 1);
              transform-origin: left center;
            }
          }
        }
      }
    }
  }

  &__cr {
    @media (--mobile) {
      margin-top: 3rem;
      text-align: center;
    }
  }
}

.nolink {
  pointer-events: none;
}
