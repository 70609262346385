html {
  &.is-hidden {
    overflow: hidden;
  }
}

.l-main {
  overflow: hidden;
}

.s-loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #fff;
  }

  &__char01 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80vw;
    height: 80vh;
    opacity: 0;
    transform: scale(1) translate(-50%, -50%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 30vw;

      @media (--mobile) {
        width: 50vw;
      }
    }
  }

  &__char02 {
    position: absolute;
    top: 46rem;
    left: 50%;
    width: 100%;
    transform: scale(1) translateX(-50%);

    @media (--mobile) {
      top: 35rem;
      left: 50%;
      width: 32rem;
      height: 100rem;
    }

    img {
      &:nth-of-type(1) {
        @media (--large) {
          position: absolute;
          top: 0;
          left: 50%;
          width: 40rem;
          transform: translateX(-50%) translateY(100vw);
        }

        @media (--mobile) {
          position: absolute;
          top: 4rem;
          left: 4rem;
          width: 25rem;
          transform: translateY(100vw);
        }
      }

      &:nth-of-type(2) {
        @media (--large) {
          position: absolute;
          top: -5rem;
          left: 50%;
          width: 24rem;
          transform: translateX(-50%) translateX(-55rem) translateY(100vw);
        }

        @media (--mobile) {
          position: absolute;
          top: 24rem;
          left: -1rem;
          width: 16rem;
          transform: translateY(100vw);
        }
      }

      &:nth-of-type(3) {
        @media (--large) {
          position: absolute;
          top: -5rem;
          left: 50%;
          width: 14rem;
          transform: translateX(-50%) translateX(30rem) translateY(100vw);
        }

        @media (--mobile) {
          position: absolute;
          top: -2rem;
          right: -1rem;
          width: 7rem;
          transform: translateY(100vw);
        }
      }

      &:nth-of-type(4) {
        @media (--large) {
          position: absolute;
          top: -12rem;
          left: 50%;
          width: 21rem;
          transform: translateX(-50%) translateX(56rem) translateY(100vw);
        }

        @media (--mobile) {
          position: absolute;
          top: 35rem;
          left: 9rem;
          width: 14rem;
          transform: translateY(100vw);
        }
      }

      &:nth-of-type(5) {
        @media (--large) {
          position: absolute;
          top: 13rem;
          left: 50%;
          width: 21rem;
          transform: translateX(-50%) translateX(-36rem) translateY(100vw);
        }

        @media (--mobile) {
          position: absolute;
          top: -1rem;
          left: -1rem;
          width: 10rem;
          transform: translateY(100vw);
        }
      }

      &:nth-of-type(6) {
        @media (--large) {
          position: absolute;
          top: 8rem;
          left: 50%;
          width: 18rem;
          transform: translateX(-50%) translateX(50rem) translateY(100vw);
        }

        @media (--mobile) {
          position: absolute;
          top: 27rem;
          right: 1rem;
          width: 11rem;
          transform: translateY(100vw);
        }
      }
    }
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 23.2rem;
    opacity: 0;
    transform: translate(-50%, -50%) translateY(-8rem);
  }
}

.s-deco {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;

  img {
    position: absolute;
    filter: blur(3.3rem);

    &:first-of-type {
      top: -15rem;
      right: -8rem;
      width: 58rem;

      @media (--mobile) {
        right: -15rem;
      }
    }

    &:last-of-type {
      top: 50%;
      left: -5rem;
      width: 58rem;
      transform: translateY(-50%) translateY(15rem);

      @media (--mobile) {
        top: auto;
        bottom: -17rem;
        left: -18rem;
        width: 39rem;
        transform: inherit;
      }
    }
  }
}

.s-mv {
  position: relative;
  z-index: 2;
  padding-top: 18rem;
  overflow: hidden;
  text-align: center;

  @media (--mobile) {
    padding-top: 11.4rem;
  }

  * {
    font-family: $fontfamilyTtl;
  }

  &__ttl {
    b {
      font-size: 3rem;
      font-weight: bold;
      color: #f24ab3;

      @media (--mobile) {
        font-size: 2rem;
      }
    }

    strong {
      font-size: 6rem;
      color: #f24ab3;

      @media (--mobile) {
        display: inline-block;
        margin-top: 1.5rem;
        font-size: 4.6rem;
        line-height: 1;
      }
    }
  }

  &__subTtl {
    margin-top: 6rem;
    font-size: 3.3rem;
    font-weight: normal;
    line-height: 1.1;
    color: #77beff;

    @media (--mobile) {
      margin-top: 4rem;
      font-size: 1.8rem;
    }

    & > span {
      position: relative;
      top: 0.6rem;
      display: inline-block;
      width: 6rem;
      height: 2.3rem;
      margin-right: 1.5rem;
      overflow: hidden;
      color: #f24ab3;

      @media (--large) {
        top: 0.9rem;
        width: 10rem;
        height: 3.8rem;
      }

      span {
        position: absolute;
        top: 50%;
        display: block;
        width: 100%;
        padding: 0.3rem 0;
        text-align: right;
        transform: translateY(-150%);
        animation: 3s;

        &:first-of-type {
          animation: 9s anime 0s infinite;
        }

        &:nth-of-type(2) {
          animation: 9s anime 3s infinite;
        }

        &:last-of-type {
          animation: 9s anime 6s infinite;
        }
      }
    }
  }

  &__char {
    position: absolute;
    top: 46rem;
    left: 50%;
    width: 84rem;
    margin-top: 3rem;
    margin-right: auto;
    margin-left: auto;
    transform: translateX(-50%);

    @media (--mobile) {
      width: 33rem;
    }
  }

  &__marquee {
    display: flex;
    width: 200vw;

    @media (--mobile) {
      width: 800vw;
    }

    &Wrap {
      position: relative;
      margin-top: 60rem;

      &::after {
        position: absolute;
        top: 0;
        left: 21rem;
        width: 13.7rem;
        height: 14.4rem;
        content: "";
        background: url(/common/images/common/char14.png) no-repeat center center/contain;
        transform: translateY(-100%) translateY(4rem);

        @media (--mobile) {
          position: absolute;
          left: 5rem;
          width: 9.7rem;
          height: 8.4rem;
        }
      }
    }

    div {
      display: flex;
      align-items: center;
      width: 100vw;
      white-space: nowrap;

      @media (--mobile) {
        width: 300vw;
      }

      &:nth-of-type(1) {
        animation: ticker 40s linear -20s infinite forwards;
      }

      &:nth-of-type(2) {
        animation: ticker2 40s linear 0s infinite forwards;
      }

      img {
        width: calc(100% / 6);
        max-width: inherit;
        height: fit-content;
        padding-left: 2rem;

        @media (--large) {
          &:hover {
            animation-play-state: paused;
          }
        }
      }
    }

    &--reverse {
      margin-top: 2rem;

      div {
        &:nth-of-type(1) {
          animation: ticker-reverse 40s linear -20s infinite forwards;
        }

        &:nth-of-type(2) {
          animation: ticker2-reverse 40s linear 0s infinite forwards;
        }
      }
    }

    &:hover {
      div {
        animation-play-state: paused;
      }
    }
  }

  &__marquee02 {
    display: flex;
    width: 250vw;
    margin-top: 5rem;

    @media (--mobile) {
      width: 500vw;
      margin-top: 3rem;
    }

    svg {
      padding-left: 3rem;
    }
  }
}

.s-about {
  position: relative;
  z-index: 2;
  margin-top: 10rem;

  @media (--mobile) {
    margin-top: 4rem;
  }
}

.s-about_wrap {
  display: flex;
  justify-content: center;

  @media (--mobile) {
    flex-direction: column;
    justify-content: center;

    &.reverse {
      margin-top: 12rem;
    }
  }

  @media (--large) {
    &:nth-of-type(odd) {
      flex-direction: row-reverse;
    }
  }

  &__left {
    width: 50rem;
    padding-top: 10rem;

    @media (--mobile) {
      width: 100%;
      padding-top: 0;
    }

    @at-root .reverse & {
      width: 66rem;
      padding-top: 10rem;

      @media (--mobile) {
        width: 100%;
        padding-top: 0;
      }
    }
  }

  &__right {
    width: 55.3rem;

    @media (--mobile) {
      width: 37.3rem;
    }

    @at-root .reverse & {
      width: 42.4rem;
      margin-right: 7.6rem;

      @media (--mobile) {
        width: 31.4rem;
        margin-right: 0;
      }
    }
  }

  &__ttl {
    font-size: 6.6rem;
    line-height: 1;

    @media (--mobile) {
      font-size: 3.6rem;
    }
  }

  &__txt {
    margin-top: 1.5rem;
    font-size: 2rem;
    line-height: 1.7;

    span {
      display: block;

      @media (--mobile) {
        padding-left: 0.5em;
        text-indent: -0.5em;
      }
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    margin-top: 4rem;
  }

  &__doc {
    position: relative;
    display: inline-block;
    padding-left: 2.5rem;
    margin-left: 3rem;
    font-size: 1.8rem;
    transition: 0.3s;

    @media (--mobile) {
      margin-left: 2.5rem;
      font-size: 1.3rem;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      width: 1.4rem;
      height: 1.9rem;
      content: "";
      background: url(/common/images/common/doc-icon.png) no-repeat center center / contain;
      transform: translateY(-50%);
    }

    @media (--large) {
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.s-feature {
  position: relative;
  z-index: 2;
  padding-top: 5rem;
  margin-top: 20rem;
  background-color: #ddecf8;

  // background: linear-gradient(to left, #eee9ed, #e8eaf6);

  @media (--mobile) {
    padding-top: 8rem;
    margin-top: 10rem;
  }

  &::after {
    position: absolute;
    top: 0.1rem;
    left: 0;
    width: 100vw;
    height: 17.7rem;
    pointer-events: none;
    content: "";
    background: url(/common/images/common/ebris01.png) no-repeat top center / contain;
    transform: translateY(-100%);

    @media (--mobile) {
      top: 0.4rem;
      height: 4.8rem;
      pointer-events: none;
    }
  }

  &__ttl {
    font-family: $fontfamilyTtl;
    font-size: 9rem;
    font-weight: bold;

    @media (--mobile) {
      font-size: 5rem;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 4.4rem;

    @media (--mobile) {
      display: block;
      margin-top: 3rem;
    }
  }

  &__ele {
    width: calc(100% / 3 - 2rem);
    padding: 4.7rem 2.5rem 4.2rem;
    text-align: center;
    background-color: white;
    border: 0.1rem solid #dceefe;

    // background: unquote("rgb(196 152 213 / 30%)");
    border-radius: 1rem;

    @media (--mobile) {
      width: 100%;
      padding: 3.5rem 2.5rem;

      &:nth-of-type(n + 2) {
        margin-top: 2rem;
      }
    }
  }

  &__img {
    width: 17rem;
    margin-right: auto;
    margin-left: auto;

    @media (--mobile) {
      width: 15rem;
    }
  }

  &__subTtl {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 7.7rem;
    margin-top: 3.2rem;
    font-size: 2.8rem;
    font-weight: lighter;

    @media (--mobile) {
      min-height: inherit;
    }
  }

  &__subList {
    margin-top: 1.4rem;

    @media (--mobile) {
      margin-top: 2rem;
    }

    li {
      position: relative;
      padding: 0.3rem 1rem 0.3rem 3.5rem;
      font-size: 1.4rem;
      color: #7638a0;
      text-align: left;

      // background: #ece4f2;
      // border-radius: 0.5rem;

      &::after {
        position: absolute;
        top: 50%;
        left: 1rem;
        width: 1.5rem;
        height: 1.1rem;
        content: "";
        background: #000;
        background: url(/common/images/common/check-icon.png) no-repeat center center / contain;
        transform: translateY(-50%);
      }

      &:nth-of-type(n + 2) {
        margin-top: 1rem;
      }
    }
  }

  &__btn {
    margin-top: 2.2rem;
  }
}

.s-area {
  position: relative;
  z-index: 2;
  padding-top: 14rem;

  // padding-bottom: 14rem;
  background-color: #ddecf8;

  // background: linear-gradient(to left, #eee9ed, #e8eaf6);

  @media (--mobile) {
    padding-top: 5rem;
    padding-bottom: 8rem;
  }

  &::after {
    position: absolute;
    bottom: 1.4rem;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 30rem;
    content: "";
    background: url(/common/images/common/ebris04.png) no-repeat bottom center / contain;
    transform: translateY(100%);

    @media (--mobile) {
      height: 8.5rem;
    }
  }

  &-char01 {
    position: absolute;
    top: 7rem;
    right: -5rem;
    width: 19.6rem;
    height: 12rem;

    @media (--mobile) {
      top: -3rem;
      right: 0;
      width: 16rem;
      height: 9.9rem;
      transform: rotate(10deg);
    }
  }

  &-char02 {
    position: absolute;
    bottom: 0;
    left: 2rem;
    width: 19.6rem;
    height: 12rem;

    @media (--mobile) {
      bottom: -10rem;
      left: 3rem;
      width: 11.5rem;
      height: 8.8rem;
    }
  }

  &-char03 {
    position: absolute;
    bottom: -15rem;
    left: 50%;
    width: 19.6rem;
    height: 12rem;
    transform: translateX(-50%);

    @media (--mobile) {
      bottom: -18rem;
      left: 30rem;
      width: 9.9rem;
      height: 10.8rem;
    }
  }

  .c-inner {
    position: relative;
    z-index: 2;

    // @media (--large) {
    //   &::after {
    //     position: absolute;
    //     bottom: -35rem;
    //     left: 50%;
    //     z-index: 99;
    //     width: 21rem;
    //     height: 19rem;
    //     content: "";
    //     background: url(/common/images/common/char06.png) no-repeat center center / contain;
    //     transform: translateX(-50%);
    //   }
    // }
  }

  &__ttl {
    font-family: $fontfamilyTtl;
    font-size: 9rem;
    font-weight: bold;

    @media (--mobile) {
      font-size: 2.6rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 15rem;
    margin-top: 5rem;

    @media (--mobile) {
      display: block;
      padding-bottom: 0;
      margin-top: 2.2rem;
    }
  }

  &__el {
    width: calc(100% / 3 - 3rem);
    padding: 2rem 4rem;
    background: #fff;
    border-radius: 0.5rem;

    @media (--mobile) {
      width: 100%;
      padding: 2rem;

      &:nth-of-type(n + 2) {
        margin-top: 2rem;
      }
    }

    @media (--large) {
      &:nth-of-type(n + 4) {
        margin-top: 3rem;
      }

      &:nth-of-type(2),
      &:nth-of-type(5) {
        transform: translateY(5rem);
      }
    }
  }

  &__img {
    display: flex;
    align-items: center;

    img {
      width: 6rem;
    }

    figcaption {
      margin-left: 2.3rem;
      font-size: 2.4rem;
      font-weight: bold;
      color: #7638a0;
    }
  }

  &__txt {
    margin-top: 2rem;
    font-size: 1.2rem;
    color: #7638a0;

    @media (--mobile) {
      margin-top: 1.5rem;
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }
}

.s-item {
  padding-bottom: 20rem;
  margin-top: 25rem;

  @media (--mobile) {
    padding-bottom: 14rem;
    margin-top: 10rem;
  }
}

.s-item-box {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  &.third {
    position: relative;

    &::before {
      position: absolute;
      top: -5rem;
      right: 0;
      width: 27.3rem;
      height: 22.8rem;
      content: "";
      background: url(/common/images/common/char07.png) no-repeat center center / contain;
    }
  }

  @media (--mobile) {
    flex-direction: column;

    &.first {
      padding-top: 10rem;
      margin-top: -10rem;
    }

    &.second {
      flex-direction: column;
    }

    &.third {
      flex-direction: column;
      margin-top: 8rem;

      &::before {
        top: -4rem;
        width: 15rem;
        height: 12.7rem;
      }
    }
  }

  &:nth-of-type(n + 2) {
    @media (--large) {
      padding-top: 5rem;
      margin-top: 12rem;
    }

    @media (--mobile) {
      padding-top: 10rem;
    }
  }

  @media (--large) {
    flex-direction: row-reverse;

    &.first {
      @media (--mobile) {
        padding-top: 10rem;
        margin-top: -10rem;
      }
    }

    &.second {
      flex-direction: row;
    }

    &.third {
      flex-direction: column-reverse;
    }
  }

  &__img {
    width: 51rem;

    @media (--mobile) {
      width: 30rem;
    }

    @at-root .second & {
      width: 30rem;

      @media (--mobile) {
        width: 26rem;
      }
    }

    @at-root .third & {
      width: 68rem;
      padding-right: 6rem;
      margin: -3rem 0 0 auto;

      @media (--mobile) {
        width: calc(100% + 4rem);
        padding-right: 0;
        margin: 0 -2rem;
      }
    }
  }

  &__ttl {
    font-family: $fontfamilyTtl;
    font-size: 9rem;

    @media (--mobile) {
      font-size: 5rem;
    }
  }

  &__txtWrap {
    width: 53.4rem;
    padding-top: 15rem;

    @media (--mobile) {
      width: 100%;
      padding-top: 2.3rem;
    }

    @media (--large) {
      @at-root .second & {
        width: 71rem;
        padding-top: 5rem;
        padding-left: 10.6rem;
      }

      @at-root .third & {
        position: relative;
        left: -8rem;
        width: 90rem;
        padding-top: 0;
      }
    }
  }

  &__txt {
    margin-top: 1.5rem;
    font-size: 3rem;
    font-weight: normal;
    line-height: 1.2;

    @media (--mobile) {
      font-size: 2.8rem;
      line-height: 1.3;
    }
  }

  &__side {
    display: flex;
    margin-top: 2.8rem;

    @media (--mobile) {
      display: block;
      margin-top: 2rem;
    }

    span {
      position: relative;
      padding-left: 3.2rem;
      font-size: 1.4rem;
      color: #7638a0;

      @media (--mobile) {
        display: block;

        &:nth-of-type(n + 2) {
          margin-top: 1rem;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 2.3rem;
        height: 1.6rem;
        content: "";
        background: url(/common/images/common/check-icon.png) no-repeat center center / contain;
        transform: translateY(-50%);
      }

      @media (--large) {
        &:nth-of-type(n + 2) {
          margin-left: 1rem;
        }
      }
    }
  }

  &__btn {
    margin-top: 4rem;

    @media (--mobile) {
      width: 21rem;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.s-bg {
  position: relative;
  z-index: 3;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #ddecf8;

  // background: linear-gradient(to right, #eee9ed, #e8eaf6);

  @media (--mobile) {
    padding-top: 3rem;
    padding-bottom: 5rem;
  }

  &::after {
    position: absolute;
    top: 0.3rem;
    left: 0;
    width: 100vw;
    height: 21.5rem;
    pointer-events: none;
    content: "";
    background: url(/common/images/common/ebris02.png) no-repeat top center/contain;
    transform: translateY(-100%);

    @media (--mobile) {
      top: 0.9rem;
      height: 6.2rem;
    }
  }

  &::before {
    position: absolute;
    bottom: 0.1rem;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 16rem;
    pointer-events: none;
    content: "";
    background: url(/common/images/common/ebris03.png) no-repeat bottom center / contain;
    transform: translateY(100%);

    @media (--mobile) {
      height: 4.3rem;
    }
  }
}

.c-backUp {
  position: relative;
  z-index: 2;
  padding-top: 10rem;
  margin-top: -10rem;

  &__ttl {
    font-family: $fontfamilyTtl;
    font-size: 8rem;

    @media (--mobile) {
      font-size: 5rem;
    }
  }

  &__txt {
    position: relative;

    // margin-top: 5.7rem;
    font-size: 4.4rem;
    font-weight: bold;

    &::after {
      position: absolute;
      top: -17rem;
      right: 0;
      width: 12.2rem;
      height: 19.3rem;
      content: "";
      background: url(/common/images/common/char15.png) no-repeat center center / contain;

      @media (--mobile) {
        width: 10.2rem;
        height: 17.3rem;
      }
    }

    @media (--mobile) {
      margin-top: 3rem;
      font-size: 2.8rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -1rem;
    margin-top: 0.6rem;

    @media (--large) {
      margin: 0 -3rem;
    }

    @media (--mobile) {
      margin: 0 -1rem;
    }
  }

  &__el {
    a {
      transition: 0.3s opacity;

      &:hover {
        opacity: 0.6;
      }
    }

    @media (--large) {
      margin: 3rem 3rem 0;
    }

    @media (--mobile) {
      margin: 3rem 1rem 0;
    }

    &:nth-of-type(1) {
      width: 17rem;

      @media (--mobile) {
        width: 15rem;
      }
    }

    &:nth-of-type(2) {
      width: 19.7rem;

      @media (--mobile) {
        width: 13rem;
      }
    }
  }
}

.s-news {
  margin-top: 12rem;
  overflow: hidden;

  @media (--mobile) {
    margin-top: 10rem;
  }

  &__inner {
    @media (--large) {
      padding-left: 10rem;
    }

    @media (--mobile) {
      padding: 0 3rem;
    }
  }

  &__ttl {
    font-family: $fontfamilyTtl;
    font-size: 4.4rem;

    @media (--mobile) {
      font-size: 4.2rem;
    }
  }

  &__list {
    margin-top: 3.6rem;
  }

  &__el {
    position: relative;
    padding: 4rem;
    background: unquote("rgb(255 255 255 / 50%)");
    border-radius: 1rem;

    @media (--mobile) {
      padding: 3rem;
    }

    &::before {
      position: absolute;
      top: -3rem;
      left: 50%;
      display: block;
      width: 6rem;
      height: 6rem;
      content: "";
      background: url(/common/images/common/news-icon.png) no-repeat center center / contain;
      transform: translateX(-50%);

      @media (--mobile) {
        width: 5rem;
        height: 5rem;
      }
    }
  }

  &__img {
    width: 100%;
  }

  &__subTtl {
    min-height: 10rem;
    margin-top: 2.4rem;
    font-size: 2.2rem;
    font-weight: lighter;

    @media (--mobile) {
      min-height: 8rem;
    }

    a {
      color: #7638a0;
      transition: 0.3s opacity;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 6rem;

    // min-height: 8rem;
    // padding: 1rem;
    margin-top: 2rem;
    background: #fff;
    border-radius: 1rem;

    &.black {
      background-color: #000;

      img {
        width: 6.8rem;
      }
    }

    img {
      display: block;
      width: 13rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__btn {
    margin-top: 2rem;
  }
}

.s-support {
  margin-top: 14rem;

  @media (--mobile) {
    margin-top: 8rem;
  }

  &__ttl {
    font-family: $fontfamilyTtl;
    font-size: 4.4rem;

    @media (--mobile) {
      font-size: 4.2rem;
    }
  }

  &__list {
    display: flex;
    margin-top: 6rem;

    @media (--mobile) {
      flex-wrap: wrap;
      justify-content: center;
      margin: 4rem -3rem 0;
    }
  }

  &__el {
    height: 12.4rem;

    @media (--large) {
      &:nth-of-type(n + 2) {
        margin-left: 13.3rem;
      }
    }

    @media (--mobile) {
      height: 9rem;
      margin: 0 1rem;
    }

    img {
      max-width: inherit;
      height: 100%;
    }
  }
}

.s-ourTeam {
  position: relative;
  z-index: 2;
  padding-top: 30rem;

  @media (--mobile) {
    padding-top: 13rem;
  }

  &__ttl {
    font-family: $fontfamilyTtl;
    font-size: 6.6rem;
    text-align: center;

    @media (--mobile) {
      font-size: 4.2rem;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    margin-top: 10rem;

    @media (--mobile) {
      flex-wrap: wrap;
      margin-top: 5rem;
    }
  }

  &__el {
    text-align: center;

    @media (--large) {
      &:nth-of-type(n + 2) {
        margin-left: 8rem;
      }
    }

    @media (--mobile) {
      width: 46%;

      &:nth-of-type(2) {
        margin-left: 8%;
      }

      &:nth-of-type(3) {
        margin-top: 2rem;
      }
    }
  }

  &__img {
    margin-right: auto;
    margin-left: auto;
    transition: 0.3s;

    @media (--large) {
      width: 20rem;
    }

    @media (--mobile) {
      width: 13rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  a {
    &:hover {
      .s-ourTeam__img {
        transform: scale(1.05);
      }
    }
  }

  &__subTtl {
    margin-top: 2.1rem;

    span {
      position: relative;
      font-size: 1.8rem; //in2xx

      @media (--large) {
        padding-right: 3.4rem;
      }

      @media (--mobile) {
        display: inline-block;
      }

      @media (--large) {
        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          width: 2.6rem;
          height: 2.6rem;
          content: "";
          background: url(/common/images/common/insta-icon.png) no-repeat center center / contain;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__job {
    margin-top: 1rem;
    font-size: 1.3rem; //in2xx
    color: #c498d5;

    @media (--mobile) {
      span {
        position: relative;
        display: inline-block;
        padding-left: 2.5rem;

        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          width: 2rem;
          height: 2rem;
          content: "";
          background: url(/common/images/common/insta-icon.png) no-repeat center center / contain;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.s-community {
  position: relative;
  z-index: 3;
  margin-top: 24.8rem;

  @media (--mobile) {
    margin-top: 10rem;
  }

  &__ttl {
    font-family: $fontfamilyTtl;
    font-size: 6.6rem;
    text-align: center;

    @media (--mobile) {
      font-size: 4.4rem;
    }
  }

  &__list {
    @media (--large) {
      display: flex;
      justify-content: center;
      margin-top: 8.4rem;
    }

    @media (--mobile) {
      margin-top: 5rem;
    }
  }

  &__el {
    @media (--large) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27.4rem;
      height: 32rem;
      text-align: center;
    }

    position: relative;
    background: #fff;
    border-radius: 0.8rem;

    &:nth-of-type(n + 2) {
      @media (--large) {
        margin-left: 8rem;
      }

      @media (--mobile) {
        margin-top: 2rem;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      width: calc(100% + 0.8rem);
      height: calc(100% + 0.8rem);
      content: "";
      background: #000;
      background: linear-gradient(to left, #8ec9ff, #a9bbe3, #c498d5, #ff63c4);
      filter: blur(0);
      border-radius: 1rem;
      transition: 0.3s filter;
      transform: translate(-50%, -50%);
    }

    &:hover {
      &::after {
        filter: blur(1.5rem);
      }
    }
  }

  &__link {
    @media (--mobile) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5rem 0;
      color: #000;
    }
  }

  &__img {
    max-width: inherit;

    @media (--mobile) {
      text-align: center;
    }

    img {
      height: 4.3rem;

      @media (--mobile) {
        height: 3.2rem;
      }
    }

    figcaption {
      margin-top: 2rem;
      font-size: 2rem;

      @media (--mobile) {
        margin-top: 1rem;
        font-size: 1.8rem;
      }
    }
  }

  &__logo {
    width: 15.6rem;
    margin-top: 1.8rem;
    margin-right: auto;
    margin-left: auto;

    @media (--mobile) {
      margin: inherit;
      margin-top: 0;
      margin-left: 3rem;
    }
  }
}

.s-pageTop {
  position: relative;
  z-index: 3;
  margin-top: 15.1rem;
  text-align: center;

  @media (--mobile) {
    padding-right: 3rem;
    margin-top: 5rem;
    text-align: right;
  }

  img {
    width: 22.7rem;

    @media (--mobile) {
      width: 13rem;
    }
  }

  a {
    display: inline-block;
    transition: 0.3s;

    @media (--large) {
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.s-b-marquee02 {
  position: relative;
  z-index: 3;
  display: flex;
  width: 250vw;
  margin-top: 8rem;

  @media (--mobile) {
    width: 500vw;
    margin-top: 5rem;
  }

  svg {
    padding-left: 3rem;
  }
}
