.c-inner {
  width: 120rem;
  margin-right: auto;
  margin-left: auto;

  @media (--mobile) {
    width: inherit;
    padding: 0 3rem;
  }
}
