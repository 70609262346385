html {
  // font-size: 62.5%; //1.0rem = 10px
  font-size: calc(10vw / 1400 * 100);

  @media (--mobile) {
    font-size: calc(10vw / 375 * 100);
  }
}

body {
  font-family: $fontfamilyTxt;
  font-size: 1rem;
  font-feature-settings: "palt";
  letter-spacing: 0;
}

a {
  color: #000;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

//text
h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
p {
  padding: 0;
  margin: 0;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

img {
  vertical-align: bottom;
}

figure {
  padding: 0;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.l-main {
  line-height: 1.2;
}

svg,
img {
  max-width: 100%;
}

@media (--mobile) {
  html,
  body {
    width: 100%;
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  dl,
  dt,
  dd,
  p {
    padding: 0;
    margin: 0;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

@media (--mobile) {
  .pc {
    display: none !important;
  }
}

@media (--large) {
  [href*="tel:"] {
    pointer-events: none;
  }

  .sp {
    display: none !important;
  }
}
