@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&family=Prompt:wght@500;700&display=swap");
/**
 * Custom media queries
 * @example
 * @media (--mobile) {
 *    styles for mobile viewport
 * }
 */
/**
 * for mobile viewoport
 */
@import url(normalize.css);
@import url(swiper/swiper-bundle.min.css);
@custom-media --mini (max-width: 320px);
@custom-media --phone (max-width: 519px);
@custom-media --tablet (min-width: 520px) and (max-width: 959px);
@custom-media --mobile (max-width: 959px);
@custom-media --large (min-width: 960px);
/**
 * for tablet and Pc. large viewport
 */
@custom-media --large (min-width: 960px);
@custom-media --desktop(min-width: 1280px);
/** http://bourbon.io/docs/#timing-functions */
/** example @include transition(all 5s $ease-in-circ);*/
/**
transition timing-function:
ease - cubic-bezier(0.25, 0.1, 0.25, 1.0) を指定したのと同じ（開始と完了を滑らかにする）（初期値）
linear - cubic-bezier(0.0, 0.0, 1.0, 1.0) を指定したのと同じ（一定）
ease-in - cubic-bezier(0.42, 0, 1.0, 1.0) を指定したのと同じ（ゆっくり始まる）
ease-out - cubic-bezier(0, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり終わる）
ease-in-out - cubic-bezier(0.42, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり始まってゆっくり終わる）
*/
/**
 * text ellipsis for block multiline
 */
/*
 unsupport IE, Edge, Fx

@mixin text-ellipsis-block($rows) {
  display: box;
  box-orient: vertical;
  line-clamp: $rows;
  overflow: hidden;
}
*/
/**
 * element size
 */
/**
 * position layout
 */
/**
 * images
 */
/**
* Variables
*/
/** extend */
@media (--large) {
  .ex-link {
    opacity: 1;
    transition: opacity 0.2s linear; }
    .ex-link:hover {
      opacity: .5; } }

@keyframes ticker {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(-100%); } }

@keyframes ticker2 {
  0% {
    transform: translateX(0%); }
  100% {
    transform: translateX(-200%); } }

@keyframes ticker-reverse {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(100%); } }

@keyframes ticker2-reverse {
  0% {
    transform: translateX(-200%); }
  100% {
    transform: translateX(0%); } }

@keyframes anime {
  0% {
    transform: translateY(-150%); }
  3% {
    transform: translateY(-50%); }
  33% {
    transform: translateY(-50%); }
  36% {
    transform: translateY(50%); }
  100% {
    transform: translateY(50%); } }

.js-inview[data-inview="fade"] {
  opacity: 0;
  transition: opacity 1s; }
  .js-inview[data-inview="fade"].is-inview {
    opacity: 1; }

.js-inview[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transform: translateY(30px); }
  .js-inview[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview[data-inview="scale"] {
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transform: scale(1.1); }
  .js-inview[data-inview="scale"].is-inview {
    opacity: 1;
    transform: scale(1); }

.js-inview[data-inview="fade-left"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(-30px); }
  .js-inview[data-inview="fade-left"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(30px); }
  .js-inview[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview[data-inview="btn"] a::before,
.js-inview[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn"] i {
  opacity: 0;
  transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn"].is-inview a::before,
.js-inview[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="btn"].is-inview i {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="btn-border"] a::after,
.js-inview[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn-border"].is-inview a::after,
.js-inview[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="bg"]::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  transition: transform 0.4s;
  transform: scale(1, 1);
  transform-origin: right top; }

.js-inview[data-inview="bg"].is-inview::after {
  transform: scale(0, 1); }

.js-inview[data-inview="bg-white"]::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  transition: transform 0.4s;
  transform: scale(1, 1);
  transform-origin: right top; }

.js-inview[data-inview="bg-white"].is-inview::after {
  transform: scale(0, 1); }

.js-inview[data-inview="bg-scale"]::before {
  transition: transform 0.8s;
  transform: translate(-50%, -50%) scale(0.5);
  transform-origin: center center; }

.js-inview[data-inview="bg-scale"].is-inview::before {
  transform: translate(-50%, -50%) scale(1); }

.js-inview-photo[data-inview="scale-small"] {
  transition: transform 1s;
  transform: scale(1.5); }
  .is-inview .js-inview-photo[data-inview="scale-small"] {
    transform: scale(1); }

.js-inview-photo-img[data-inview="scale-small"] {
  transition: transform 1s;
  transform: translateX(-50%) scale(1.5); }
  .is-inview .js-inview-photo-img[data-inview="scale-small"] {
    transform: translateX(-50%) scale(1); }

.js-inview-eachItem[data-inview="fade"] {
  opacity: 0;
  transition: opacity 0.8s; }
  .js-inview-eachItem[data-inview="fade"].is-inview {
    opacity: 1; }

.js-inview-eachItem[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }
  .js-inview-eachItem[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem[data-inview="scale"] {
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transform: scale(1.1); }
  .js-inview-eachItem[data-inview="scale"].is-inview {
    opacity: 1;
    transform: scale(1); }

.js-inview-eachItem[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview-eachItem[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(30px); }
  .js-inview-eachItem[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview-eachItem[data-inview="btn"] a::before,
.js-inview-eachItem[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-eachItem[data-inview="btn"].is-inview a::before,
.js-inview-eachItem[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview-eachItem[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="btn-border"] a::after,
.js-inview-eachItem[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-eachItem[data-inview="btn-border"].is-inview a::after,
.js-inview-eachItem[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview-eachItem[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="list-border"]::before {
  transition: transform 0.8s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="list-border"] span,
.js-inview-eachItem[data-inview="list-border"] figure,
.js-inview-eachItem[data-inview="list-border"] a {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }

.js-inview-eachItem[data-inview="list-border"] i {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(-20px) rotate(45deg); }

.js-inview-eachItem[data-inview="list-border"].is-inview::before {
  transform: scale(1); }

.js-inview-eachItem[data-inview="list-border"].is-inview span,
.js-inview-eachItem[data-inview="list-border"].is-inview figure,
.js-inview-eachItem[data-inview="list-border"].is-inview a {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="list-border"].is-inview i {
  opacity: 1;
  transform: translateX(-10px) rotate(45deg); }

.js-inview-eachItem-fast[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateY(10px); }
  .js-inview-eachItem-fast[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade"] {
  opacity: 0;
  transition: transform 0.8s; }
  .js-inview-eachItem-delay[data-inview="fade"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }
  .js-inview-eachItem-delay[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(30px); }
  .js-inview-eachItem-delay[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview-eachItem-delay[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-all[data-inview="btn"] a::before,
.js-inview-all[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-all[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-all[data-inview="btn"].is-inview a::before,
.js-inview-all[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview-all[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-all[data-inview="btn-border"] a::after,
.js-inview-all[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-all[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-all[data-inview="btn-border"].is-inview a::after,
.js-inview-all[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview-all[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

_:-ms-lang(x)::-ms-backdrop,
.js-inview-photo[data-inview="scale-small"] {
  transform: scale(1); }

_:-ms-lang(x)::-ms-backdrop,
.js-inview-photo-img[data-inview="scale-small"] {
  transform: translateX(-50%) scale(1); }

/* == @import "../node_modules/normalize.css/normalize.css"; */
[v-cloak] {
  display: none; }

html {
  font-size: calc(10vw / 1400 * 100); }
  @media (--mobile) {
    html {
      font-size: calc(10vw / 375 * 100); } }

body {
  font-family: "Prompt", sans-serif;
  font-size: 1rem;
  font-feature-settings: "palt";
  letter-spacing: 0; }

a {
  color: #000;
  text-decoration: none; }

*,
*::before,
*::after {
  box-sizing: border-box; }

h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
p {
  padding: 0;
  margin: 0; }

ul,
ol,
li {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

img {
  vertical-align: bottom; }

figure {
  padding: 0;
  margin: 0; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0; }

input[type="number"] {
  appearance: textfield; }

.l-main {
  line-height: 1.2; }

svg,
img {
  max-width: 100%; }

@media (--mobile) {
  html,
  body {
    width: 100%;
    height: 100%; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  dl,
  dt,
  dd,
  p {
    padding: 0;
    margin: 0; }
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%; } }

@media (--mobile) {
  .pc {
    display: none !important; } }

@media (--large) {
  [href*="tel:"] {
    pointer-events: none; }
  .sp {
    display: none !important; } }

.l-footer {
  position: relative;
  z-index: 3;
  padding: 0 4rem 2rem;
  margin-top: 9rem; }
  @media (--mobile) {
    .l-footer {
      padding: 0 3rem 3rem;
      margin-top: 8rem; } }
  .l-footer__top {
    display: flex;
    justify-content: space-between; }
    @media (--mobile) {
      .l-footer__top {
        display: block; } }
  .l-footer__logo {
    width: 18.3rem; }
  .l-footer__fList {
    display: flex; }
    @media (--mobile) {
      .l-footer__fList {
        display: block;
        margin-top: 5rem; } }
  .l-footer__fEl:nth-of-type(n + 2) {
    margin-left: 3.2rem; }
    @media (--mobile) {
      .l-footer__fEl:nth-of-type(n + 2) {
        margin-top: 1rem;
        margin-left: 0; } }
  .l-footer__fLink {
    position: relative;
    font-size: 1.8rem; }
    @media (--mobile) {
      .l-footer__fLink {
        position: relative;
        display: block;
        padding: 1rem 0; } }
    @media (--large) {
      .l-footer__fLink::after {
        position: absolute;
        bottom: 0.2rem;
        left: 0;
        width: 100%;
        height: 0.1rem;
        margin: auto;
        content: "";
        background: #8e8e8e;
        transition: transform 0.3s;
        transform: scale(0, 1);
        transform-origin: right center; }
      .l-footer__fLink:hover::after {
        transform: scale(1, 1);
        transform-origin: left center; } }
  .l-footer__plus {
    position: absolute;
    top: 50%;
    right: 0;
    width: 2.5rem;
    height: 2.5rem;
    border: 0.1rem solid #000;
    border-radius: 999rem;
    transform: translateY(-50%); }
    .l-footer__plus::after, .l-footer__plus::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.1rem;
      height: 60%;
      content: "";
      background: #000;
      transition: 0.3s;
      transform: translate(-50%, -50%); }
    .l-footer__plus::before {
      transform: translate(-50%, -50%) rotate(90deg); }
    .l-footer__plus.is-active::after {
      transform: translate(-50%, -50%) rotate(90deg); }
  .l-footer__sList {
    margin-top: 1rem;
    font-size: 1.3rem; }
    @media (--mobile) {
      .l-footer__sList {
        display: none; } }
  .l-footer__sEl:nth-of-type(n + 2) {
    margin-top: 1rem; }
  .l-footer__sLink {
    position: relative;
    color: #8e8e8e; }
    @media (--large) {
      .l-footer__sLink::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1rem;
        margin: auto;
        content: "";
        background: #8e8e8e;
        transition: transform 0.3s;
        transform: scale(0, 1);
        transform-origin: right center; }
      .l-footer__sLink:hover::after {
        transform: scale(1, 1);
        transform-origin: left center; } }
  .l-footer__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 6.4rem; }
    @media (--mobile) {
      .l-footer__bottom {
        flex-direction: column-reverse; } }
  .l-footer__list {
    display: flex; }
    .l-footer__list li:nth-of-type(n + 2) {
      padding-left: 2rem;
      margin-left: 2rem;
      border-left: 0.1rem solid #8e8e8e; }
      @media (--mobile) {
        .l-footer__list li:nth-of-type(n + 2) {
          padding-left: 1rem;
          margin-left: 1rem; } }
    .l-footer__list li a {
      position: relative;
      display: inline-block;
      font-size: 1.4rem;
      color: #8e8e8e; }
      @media (--large) {
        .l-footer__list li a::after {
          position: absolute;
          bottom: -0.2rem;
          left: 0;
          width: 100%;
          height: 0.1rem;
          margin: auto;
          content: "";
          background: #8e8e8e;
          transition: transform 0.3s;
          transform: scale(0, 1);
          transform-origin: right center; }
        .l-footer__list li a:hover::after {
          transform: scale(1, 1);
          transform-origin: left center; } }
  @media (--mobile) {
    .l-footer__cr {
      margin-top: 3rem;
      text-align: center; } }

.nolink {
  pointer-events: none; }

.l-header {
  position: fixed;
  left: 50%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateX(-50%); }
  @media (--large) {
    .l-header {
      top: 3.7rem;
      width: calc(100% - 4rem);
      height: 6.5rem;
      padding: 0 2rem;
      background: rgb(255 255 255 / 0%);
      border-radius: 999rem;
      transition: 0.5s; } }
  @media (--mobile) {
    .l-header {
      top: 4.5rem;
      width: calc(100% - 6rem);
      transition: 0.5s; } }
  @media (--large) {
    .l-header.is-scroll {
      top: 2rem;
      padding: 0 3rem;
      background: rgb(255 255 255 / 50%);
      box-shadow: 0 0 9rem rgb(0 0 0 / 10%); } }
  .l-header__left {
    width: 18rem; }
    @media (--mobile) {
      .l-header__left {
        width: 14rem; } }
  .l-header__drawer {
    position: relative;
    z-index: 4;
    width: 2.4rem;
    height: 1.6rem; }
    .l-header__drawer span {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 2px;
      background: #000;
      border-radius: 999rem;
      transition: 0.3s;
      transform: translateY(-50%); }
      .l-header__drawer span:first-of-type {
        top: 0; }
      .l-header__drawer span:nth-of-type(3) {
        top: inherit;
        bottom: 0;
        transform: translateY(50%); }
      .is-active .l-header__drawer span:first-of-type {
        top: 50%;
        transform: translateY(-50%) rotate(45deg); }
      .is-active .l-header__drawer span:nth-of-type(2) {
        opacity: 0;
        transform: translateX(2rem); }
      .is-active .l-header__drawer span:nth-of-type(3) {
        bottom: 50%;
        transform: translateY(50%) rotate(-45deg); }
  @media (--large) {
    .l-header__right {
      display: flex;
      align-items: center; } }
  @media (--mobile) {
    .l-header__right {
      display: block !important; } }
  @media (--mobile) {
    .l-header__rightWrap {
      position: fixed;
      top: -4.5rem;
      right: -3rem;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80vw;
      height: 100vh;
      background: #fff;
      transition: 0.3s;
      transform: translateX(100%); }
      .is-active .l-header__rightWrap {
        transform: translateX(0); } }
  @media (--large) {
    .l-header__list {
      display: flex;
      align-items: center; } }
  @media (--large) {
    .l-header__ele:nth-of-type(n + 2) {
      margin-left: 3.2rem; } }
  @media (--mobile) {
    .l-header__ele:nth-of-type(n + 2) {
      margin-top: 3rem; } }
  .l-header__ele a {
    display: inline-block;
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
    transition: 0.3s; }
    @media (--mobile) {
      .l-header__ele a {
        font-size: 2.7rem; } }
    @media (--large) {
      .l-header__ele a:hover {
        transform: scale(1.05); } }
  .l-header__cv {
    width: 14rem !important;
    height: 3.4rem !important;
    font-size: 1.4rem !important;
    font-weight: lighter !important;
    letter-spacing: 0.05rem; }
    @media (--large) {
      .l-header__cv {
        margin-left: 3.5rem !important; } }
    @media (--mobile) {
      .l-header__cv {
        width: 19.3rem !important;
        height: 5rem !important;
        margin-top: 4rem !important;
        font-size: 2.2rem !important; } }
  .l-header__overlay {
    position: fixed;
    top: -4.5rem;
    right: -3rem;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background: rgb(160 124 173 / 90%);
    opacity: 0;
    transition: 0.3s; }
    .is-active .l-header__overlay {
      pointer-events: inherit;
      opacity: 1; }

/**
 * main.scss
 */
.c-btnCv {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
  height: 3.5rem;
  font-size: 2rem;
  font-weight: bold;
  background: #fff;
  border-radius: 0.8rem; }
  @media (--mobile) {
    .c-btnCv {
      width: 14.8rem;
      font-size: 1.8rem; } }
  .c-btnCv::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: calc(100% + 0.8rem);
    height: calc(100% + 0.8rem);
    content: "";
    background: #000;
    background: linear-gradient(to left, #8ec9ff, #a9bbe3, #c498d5, #ff63c4);
    filter: blur(0);
    border-radius: 1rem;
    transition: 0.3s filter;
    transform: translate(-50%, -50%); }
  .c-btnCv:hover::after {
    filter: blur(1.5rem); }

.c-btnCv02 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26rem;
  max-width: 100%;
  height: 4rem;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.6rem;
  color: #fff;
  background: #7638a0;
  border: 0.2rem solid #7638a0;
  border-radius: 999rem;
  transition: 0.3s; }
  .c-btnCv02 svg {
    position: absolute;
    top: 50%;
    right: 2rem;
    width: 1.5rem;
    transition: 0.3s;
    transform: translateY(-50%); }
    .c-btnCv02 svg * {
      transition: 0.3s; }
  @media (--large) {
    .c-btnCv02:hover {
      color: #7638a0;
      background: #fff; }
      .c-btnCv02:hover svg * {
        fill: #7638a0; } }

.c-btn-pink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21rem;
  height: 3.5rem;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.05rem;
  background-color: #f24ab3;
  border-radius: 25rem;
  transition: 0.3s all; }
  .c-btn-pink:hover {
    color: #f24ab3;
    background-color: #fff;
    border: 0.2rem solid #f24ab3; }

.comingsoon {
  pointer-events: none; }

.c-inner {
  width: 120rem;
  margin-right: auto;
  margin-left: auto; }
  @media (--mobile) {
    .c-inner {
      width: inherit;
      padding: 0 3rem; } }

.c-row {
  display: flex; }

.c-google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden; }

.c-google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

/**
* wordpress
* WYSIWYG compatchble style
*/
.c-wp-post_article strong {
  font-weight: bold; }

.c-wp-post_article em {
  font-style: italic; }

.c-wp-post_article ul {
  margin-left: 1em;
  display: block;
  list-style-type: disc !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article ol {
  display: block;
  list-style-type: decimal !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article li {
  display: list-item;
  text-align: match-parent; }

.c-wp-post_article img {
  width: auto; }

.c-wp-post_article .alignleft {
  display: inline;
  float: left; }

.c-wp-post_article .alignright {
  display: inline;
  float: right; }

.c-wp-post_article .aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.c-wp-post_article blockquote.alignleft,
.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article img.alignleft {
  margin: 0.4em 1.6em 1.6em 0; }

.c-wp-post_article blockquote.alignright,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article img.alignright {
  margin: 0.4em 0 1.6em 1.6em; }

.c-wp-post_article blockquote.aligncenter,
.c-wp-post_article .wp-caption.aligncenter,
.c-wp-post_article img.aligncenter {
  clear: both;
  margin-top: 0.4em;
  margin-bottom: 1.6em; }

.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article .wp-caption.aligncenter {
  margin-bottom: 1.2em; }

/**
 * clearfix micro
 */
.clearfix:after {
  content: "";
  clear: both;
  display: block; }

/**
 * text ellipsis., text...
 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-hide {
  display: none; }

.u-click {
  cursor: pointer; }

@media (--large) {
  .for-mobile {
    display: none; } }

@media (--mobile) {
  .for-large {
    display: none; } }

.u-objectFit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; }

.js-parallax {
  overflow: hidden; }
  .js-parallax img {
    transform: scale(1.1) translateY(20px); }
  .js-parallax--reverse img {
    transform: scale(1.1) translateY(-20px); }

/**
 * BrowserSync
 */
#__bs_notify__ {
  display: none !important;
  font-size: 8px !important;
  opacity: .25;
  max-height: 50px; }

.__debug_mihon__ {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto 0 auto;
  z-index: 100000;
  pointer-events: none;
  cursor: none;
  width: 100%;
  overflow: hidden;
  text-align: center;
  animation: __debug_mihon__ 1s infinite; }

.__debug_mihon__ {
  padding-top: 0px;
  margin-top: 0px; }

@-webkit-keyframes __debug_mihon__ {
  0% {
    opacity: 0; }
  60% {
    opacity: .5; }
  100% {
    opacity: .5; } }

/*
@example
 <div class="mihon">
  <img src="./images/sample1.png" alt="" class="type_pc">
  <img src="./images/sample2.png" alt="" class="type_sp">
</div>
*/
html.is-hidden {
  overflow: hidden; }

.l-main {
  overflow: hidden; }

.s-loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .s-loading__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #fff; }
  .s-loading__char01 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80vw;
    height: 80vh;
    opacity: 0;
    transform: scale(1) translate(-50%, -50%); }
    .s-loading__char01 img {
      position: absolute;
      top: 0;
      left: 0;
      width: 30vw; }
      @media (--mobile) {
        .s-loading__char01 img {
          width: 50vw; } }
  .s-loading__char02 {
    position: absolute;
    top: 46rem;
    left: 50%;
    width: 100%;
    transform: scale(1) translateX(-50%); }
    @media (--mobile) {
      .s-loading__char02 {
        top: 35rem;
        left: 50%;
        width: 32rem;
        height: 100rem; } }
    @media (--large) {
      .s-loading__char02 img:nth-of-type(1) {
        position: absolute;
        top: 0;
        left: 50%;
        width: 40rem;
        transform: translateX(-50%) translateY(100vw); } }
    @media (--mobile) {
      .s-loading__char02 img:nth-of-type(1) {
        position: absolute;
        top: 4rem;
        left: 4rem;
        width: 25rem;
        transform: translateY(100vw); } }
    @media (--large) {
      .s-loading__char02 img:nth-of-type(2) {
        position: absolute;
        top: -5rem;
        left: 50%;
        width: 24rem;
        transform: translateX(-50%) translateX(-55rem) translateY(100vw); } }
    @media (--mobile) {
      .s-loading__char02 img:nth-of-type(2) {
        position: absolute;
        top: 24rem;
        left: -1rem;
        width: 16rem;
        transform: translateY(100vw); } }
    @media (--large) {
      .s-loading__char02 img:nth-of-type(3) {
        position: absolute;
        top: -5rem;
        left: 50%;
        width: 14rem;
        transform: translateX(-50%) translateX(30rem) translateY(100vw); } }
    @media (--mobile) {
      .s-loading__char02 img:nth-of-type(3) {
        position: absolute;
        top: -2rem;
        right: -1rem;
        width: 7rem;
        transform: translateY(100vw); } }
    @media (--large) {
      .s-loading__char02 img:nth-of-type(4) {
        position: absolute;
        top: -12rem;
        left: 50%;
        width: 21rem;
        transform: translateX(-50%) translateX(56rem) translateY(100vw); } }
    @media (--mobile) {
      .s-loading__char02 img:nth-of-type(4) {
        position: absolute;
        top: 35rem;
        left: 9rem;
        width: 14rem;
        transform: translateY(100vw); } }
    @media (--large) {
      .s-loading__char02 img:nth-of-type(5) {
        position: absolute;
        top: 13rem;
        left: 50%;
        width: 21rem;
        transform: translateX(-50%) translateX(-36rem) translateY(100vw); } }
    @media (--mobile) {
      .s-loading__char02 img:nth-of-type(5) {
        position: absolute;
        top: -1rem;
        left: -1rem;
        width: 10rem;
        transform: translateY(100vw); } }
    @media (--large) {
      .s-loading__char02 img:nth-of-type(6) {
        position: absolute;
        top: 8rem;
        left: 50%;
        width: 18rem;
        transform: translateX(-50%) translateX(50rem) translateY(100vw); } }
    @media (--mobile) {
      .s-loading__char02 img:nth-of-type(6) {
        position: absolute;
        top: 27rem;
        right: 1rem;
        width: 11rem;
        transform: translateY(100vw); } }
  .s-loading__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 23.2rem;
    opacity: 0;
    transform: translate(-50%, -50%) translateY(-8rem); }

.s-deco {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  pointer-events: none; }
  .s-deco img {
    position: absolute;
    filter: blur(3.3rem); }
    .s-deco img:first-of-type {
      top: -15rem;
      right: -8rem;
      width: 58rem; }
      @media (--mobile) {
        .s-deco img:first-of-type {
          right: -15rem; } }
    .s-deco img:last-of-type {
      top: 50%;
      left: -5rem;
      width: 58rem;
      transform: translateY(-50%) translateY(15rem); }
      @media (--mobile) {
        .s-deco img:last-of-type {
          top: auto;
          bottom: -17rem;
          left: -18rem;
          width: 39rem;
          transform: inherit; } }

.s-mv {
  position: relative;
  z-index: 2;
  padding-top: 18rem;
  overflow: hidden;
  text-align: center; }
  @media (--mobile) {
    .s-mv {
      padding-top: 11.4rem; } }
  .s-mv * {
    font-family: "Montserrat", sans-serif; }
  .s-mv__ttl b {
    font-size: 3rem;
    font-weight: bold;
    color: #f24ab3; }
    @media (--mobile) {
      .s-mv__ttl b {
        font-size: 2rem; } }
  .s-mv__ttl strong {
    font-size: 6rem;
    color: #f24ab3; }
    @media (--mobile) {
      .s-mv__ttl strong {
        display: inline-block;
        margin-top: 1.5rem;
        font-size: 4.6rem;
        line-height: 1; } }
  .s-mv__subTtl {
    margin-top: 6rem;
    font-size: 3.3rem;
    font-weight: normal;
    line-height: 1.1;
    color: #77beff; }
    @media (--mobile) {
      .s-mv__subTtl {
        margin-top: 4rem;
        font-size: 1.8rem; } }
    .s-mv__subTtl > span {
      position: relative;
      top: 0.6rem;
      display: inline-block;
      width: 6rem;
      height: 2.3rem;
      margin-right: 1.5rem;
      overflow: hidden;
      color: #f24ab3; }
      @media (--large) {
        .s-mv__subTtl > span {
          top: 0.9rem;
          width: 10rem;
          height: 3.8rem; } }
      .s-mv__subTtl > span span {
        position: absolute;
        top: 50%;
        display: block;
        width: 100%;
        padding: 0.3rem 0;
        text-align: right;
        transform: translateY(-150%);
        animation: 3s; }
        .s-mv__subTtl > span span:first-of-type {
          animation: 9s anime 0s infinite; }
        .s-mv__subTtl > span span:nth-of-type(2) {
          animation: 9s anime 3s infinite; }
        .s-mv__subTtl > span span:last-of-type {
          animation: 9s anime 6s infinite; }
  .s-mv__char {
    position: absolute;
    top: 46rem;
    left: 50%;
    width: 84rem;
    margin-top: 3rem;
    margin-right: auto;
    margin-left: auto;
    transform: translateX(-50%); }
    @media (--mobile) {
      .s-mv__char {
        width: 33rem; } }
  .s-mv__marquee {
    display: flex;
    width: 200vw; }
    @media (--mobile) {
      .s-mv__marquee {
        width: 800vw; } }
    .s-mv__marqueeWrap {
      position: relative;
      margin-top: 60rem; }
      .s-mv__marqueeWrap::after {
        position: absolute;
        top: 0;
        left: 21rem;
        width: 13.7rem;
        height: 14.4rem;
        content: "";
        background: url(/common/images/common/char14.png) no-repeat center center/contain;
        transform: translateY(-100%) translateY(4rem); }
        @media (--mobile) {
          .s-mv__marqueeWrap::after {
            position: absolute;
            left: 5rem;
            width: 9.7rem;
            height: 8.4rem; } }
    .s-mv__marquee div {
      display: flex;
      align-items: center;
      width: 100vw;
      white-space: nowrap; }
      @media (--mobile) {
        .s-mv__marquee div {
          width: 300vw; } }
      .s-mv__marquee div:nth-of-type(1) {
        animation: ticker 40s linear -20s infinite forwards; }
      .s-mv__marquee div:nth-of-type(2) {
        animation: ticker2 40s linear 0s infinite forwards; }
      .s-mv__marquee div img {
        width: calc(100% / 6);
        max-width: inherit;
        height: fit-content;
        padding-left: 2rem; }
        @media (--large) {
          .s-mv__marquee div img:hover {
            animation-play-state: paused; } }
    .s-mv__marquee--reverse {
      margin-top: 2rem; }
      .s-mv__marquee--reverse div:nth-of-type(1) {
        animation: ticker-reverse 40s linear -20s infinite forwards; }
      .s-mv__marquee--reverse div:nth-of-type(2) {
        animation: ticker2-reverse 40s linear 0s infinite forwards; }
    .s-mv__marquee:hover div {
      animation-play-state: paused; }
  .s-mv__marquee02 {
    display: flex;
    width: 250vw;
    margin-top: 5rem; }
    @media (--mobile) {
      .s-mv__marquee02 {
        width: 500vw;
        margin-top: 3rem; } }
    .s-mv__marquee02 svg {
      padding-left: 3rem; }

.s-about {
  position: relative;
  z-index: 2;
  margin-top: 10rem; }
  @media (--mobile) {
    .s-about {
      margin-top: 4rem; } }

.s-about_wrap {
  display: flex;
  justify-content: center; }
  @media (--mobile) {
    .s-about_wrap {
      flex-direction: column;
      justify-content: center; }
      .s-about_wrap.reverse {
        margin-top: 12rem; } }
  @media (--large) {
    .s-about_wrap:nth-of-type(odd) {
      flex-direction: row-reverse; } }
  .s-about_wrap__left {
    width: 50rem;
    padding-top: 10rem; }
    @media (--mobile) {
      .s-about_wrap__left {
        width: 100%;
        padding-top: 0; } }
    .reverse .s-about_wrap__left {
      width: 66rem;
      padding-top: 10rem; }
      @media (--mobile) {
        .reverse .s-about_wrap__left {
          width: 100%;
          padding-top: 0; } }
  .s-about_wrap__right {
    width: 55.3rem; }
    @media (--mobile) {
      .s-about_wrap__right {
        width: 37.3rem; } }
    .reverse .s-about_wrap__right {
      width: 42.4rem;
      margin-right: 7.6rem; }
      @media (--mobile) {
        .reverse .s-about_wrap__right {
          width: 31.4rem;
          margin-right: 0; } }
  .s-about_wrap__ttl {
    font-size: 6.6rem;
    line-height: 1; }
    @media (--mobile) {
      .s-about_wrap__ttl {
        font-size: 3.6rem; } }
  .s-about_wrap__txt {
    margin-top: 1.5rem;
    font-size: 2rem;
    line-height: 1.7; }
    .s-about_wrap__txt span {
      display: block; }
      @media (--mobile) {
        .s-about_wrap__txt span {
          padding-left: 0.5em;
          text-indent: -0.5em; } }
  .s-about_wrap__btn {
    display: flex;
    align-items: center;
    margin-top: 4rem; }
  .s-about_wrap__doc {
    position: relative;
    display: inline-block;
    padding-left: 2.5rem;
    margin-left: 3rem;
    font-size: 1.8rem;
    transition: 0.3s; }
    @media (--mobile) {
      .s-about_wrap__doc {
        margin-left: 2.5rem;
        font-size: 1.3rem; } }
    .s-about_wrap__doc::after {
      position: absolute;
      top: 50%;
      left: 0;
      width: 1.4rem;
      height: 1.9rem;
      content: "";
      background: url(/common/images/common/doc-icon.png) no-repeat center center/contain;
      transform: translateY(-50%); }
    @media (--large) {
      .s-about_wrap__doc:hover {
        transform: scale(1.05); } }

.s-feature {
  position: relative;
  z-index: 2;
  padding-top: 5rem;
  margin-top: 20rem;
  background-color: #ddecf8; }
  @media (--mobile) {
    .s-feature {
      padding-top: 8rem;
      margin-top: 10rem; } }
  .s-feature::after {
    position: absolute;
    top: 0.1rem;
    left: 0;
    width: 100vw;
    height: 17.7rem;
    pointer-events: none;
    content: "";
    background: url(/common/images/common/ebris01.png) no-repeat top center/contain;
    transform: translateY(-100%); }
    @media (--mobile) {
      .s-feature::after {
        top: 0.4rem;
        height: 4.8rem;
        pointer-events: none; } }
  .s-feature__ttl {
    font-family: "Montserrat", sans-serif;
    font-size: 9rem;
    font-weight: bold; }
    @media (--mobile) {
      .s-feature__ttl {
        font-size: 5rem; } }
  .s-feature__list {
    display: flex;
    justify-content: space-between;
    margin-top: 4.4rem; }
    @media (--mobile) {
      .s-feature__list {
        display: block;
        margin-top: 3rem; } }
  .s-feature__ele {
    width: calc(100% / 3 - 2rem);
    padding: 4.7rem 2.5rem 4.2rem;
    text-align: center;
    background-color: white;
    border: 0.1rem solid #dceefe;
    border-radius: 1rem; }
    @media (--mobile) {
      .s-feature__ele {
        width: 100%;
        padding: 3.5rem 2.5rem; }
        .s-feature__ele:nth-of-type(n + 2) {
          margin-top: 2rem; } }
  .s-feature__img {
    width: 17rem;
    margin-right: auto;
    margin-left: auto; }
    @media (--mobile) {
      .s-feature__img {
        width: 15rem; } }
  .s-feature__subTtl {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 7.7rem;
    margin-top: 3.2rem;
    font-size: 2.8rem;
    font-weight: lighter; }
    @media (--mobile) {
      .s-feature__subTtl {
        min-height: inherit; } }
  .s-feature__subList {
    margin-top: 1.4rem; }
    @media (--mobile) {
      .s-feature__subList {
        margin-top: 2rem; } }
    .s-feature__subList li {
      position: relative;
      padding: 0.3rem 1rem 0.3rem 3.5rem;
      font-size: 1.4rem;
      color: #7638a0;
      text-align: left; }
      .s-feature__subList li::after {
        position: absolute;
        top: 50%;
        left: 1rem;
        width: 1.5rem;
        height: 1.1rem;
        content: "";
        background: #000;
        background: url(/common/images/common/check-icon.png) no-repeat center center/contain;
        transform: translateY(-50%); }
      .s-feature__subList li:nth-of-type(n + 2) {
        margin-top: 1rem; }
  .s-feature__btn {
    margin-top: 2.2rem; }

.s-area {
  position: relative;
  z-index: 2;
  padding-top: 14rem;
  background-color: #ddecf8; }
  @media (--mobile) {
    .s-area {
      padding-top: 5rem;
      padding-bottom: 8rem; } }
  .s-area::after {
    position: absolute;
    bottom: 1.4rem;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 30rem;
    content: "";
    background: url(/common/images/common/ebris04.png) no-repeat bottom center/contain;
    transform: translateY(100%); }
    @media (--mobile) {
      .s-area::after {
        height: 8.5rem; } }
  .s-area-char01 {
    position: absolute;
    top: 7rem;
    right: -5rem;
    width: 19.6rem;
    height: 12rem; }
    @media (--mobile) {
      .s-area-char01 {
        top: -3rem;
        right: 0;
        width: 16rem;
        height: 9.9rem;
        transform: rotate(10deg); } }
  .s-area-char02 {
    position: absolute;
    bottom: 0;
    left: 2rem;
    width: 19.6rem;
    height: 12rem; }
    @media (--mobile) {
      .s-area-char02 {
        bottom: -10rem;
        left: 3rem;
        width: 11.5rem;
        height: 8.8rem; } }
  .s-area-char03 {
    position: absolute;
    bottom: -15rem;
    left: 50%;
    width: 19.6rem;
    height: 12rem;
    transform: translateX(-50%); }
    @media (--mobile) {
      .s-area-char03 {
        bottom: -18rem;
        left: 30rem;
        width: 9.9rem;
        height: 10.8rem; } }
  .s-area .c-inner {
    position: relative;
    z-index: 2; }
  .s-area__ttl {
    font-family: "Montserrat", sans-serif;
    font-size: 9rem;
    font-weight: bold; }
    @media (--mobile) {
      .s-area__ttl {
        font-size: 2.6rem; } }
  .s-area__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 15rem;
    margin-top: 5rem; }
    @media (--mobile) {
      .s-area__list {
        display: block;
        padding-bottom: 0;
        margin-top: 2.2rem; } }
  .s-area__el {
    width: calc(100% / 3 - 3rem);
    padding: 2rem 4rem;
    background: #fff;
    border-radius: 0.5rem; }
    @media (--mobile) {
      .s-area__el {
        width: 100%;
        padding: 2rem; }
        .s-area__el:nth-of-type(n + 2) {
          margin-top: 2rem; } }
    @media (--large) {
      .s-area__el:nth-of-type(n + 4) {
        margin-top: 3rem; }
      .s-area__el:nth-of-type(2), .s-area__el:nth-of-type(5) {
        transform: translateY(5rem); } }
  .s-area__img {
    display: flex;
    align-items: center; }
    .s-area__img img {
      width: 6rem; }
    .s-area__img figcaption {
      margin-left: 2.3rem;
      font-size: 2.4rem;
      font-weight: bold;
      color: #7638a0; }
  .s-area__txt {
    margin-top: 2rem;
    font-size: 1.2rem;
    color: #7638a0; }
    @media (--mobile) {
      .s-area__txt {
        margin-top: 1.5rem;
        font-size: 1.4rem;
        line-height: 1.5; } }

.s-item {
  padding-bottom: 20rem;
  margin-top: 25rem; }
  @media (--mobile) {
    .s-item {
      padding-bottom: 14rem;
      margin-top: 10rem; } }

.s-item-box {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center; }
  .s-item-box.third {
    position: relative; }
    .s-item-box.third::before {
      position: absolute;
      top: -5rem;
      right: 0;
      width: 27.3rem;
      height: 22.8rem;
      content: "";
      background: url(/common/images/common/char07.png) no-repeat center center/contain; }
  @media (--mobile) {
    .s-item-box {
      flex-direction: column; }
      .s-item-box.first {
        padding-top: 10rem;
        margin-top: -10rem; }
      .s-item-box.second {
        flex-direction: column; }
      .s-item-box.third {
        flex-direction: column;
        margin-top: 8rem; }
        .s-item-box.third::before {
          top: -4rem;
          width: 15rem;
          height: 12.7rem; } }
  @media (--large) {
    .s-item-box:nth-of-type(n + 2) {
      padding-top: 5rem;
      margin-top: 12rem; } }
  @media (--mobile) {
    .s-item-box:nth-of-type(n + 2) {
      padding-top: 10rem; } }
  @media (--large) {
    .s-item-box {
      flex-direction: row-reverse; } }
  @media (--large) and (--mobile) {
    .s-item-box.first {
      padding-top: 10rem;
      margin-top: -10rem; } }
  @media (--large) {
      .s-item-box.second {
        flex-direction: row; }
      .s-item-box.third {
        flex-direction: column-reverse; } }
  .s-item-box__img {
    width: 51rem; }
    @media (--mobile) {
      .s-item-box__img {
        width: 30rem; } }
    .second .s-item-box__img {
      width: 30rem; }
      @media (--mobile) {
        .second .s-item-box__img {
          width: 26rem; } }
    .third .s-item-box__img {
      width: 68rem;
      padding-right: 6rem;
      margin: -3rem 0 0 auto; }
      @media (--mobile) {
        .third .s-item-box__img {
          width: calc(100% + 4rem);
          padding-right: 0;
          margin: 0 -2rem; } }
  .s-item-box__ttl {
    font-family: "Montserrat", sans-serif;
    font-size: 9rem; }
    @media (--mobile) {
      .s-item-box__ttl {
        font-size: 5rem; } }
  .s-item-box__txtWrap {
    width: 53.4rem;
    padding-top: 15rem; }
    @media (--mobile) {
      .s-item-box__txtWrap {
        width: 100%;
        padding-top: 2.3rem; } }
    @media (--large) {
      .second .s-item-box__txtWrap {
        width: 71rem;
        padding-top: 5rem;
        padding-left: 10.6rem; }
      .third .s-item-box__txtWrap {
        position: relative;
        left: -8rem;
        width: 90rem;
        padding-top: 0; } }
  .s-item-box__txt {
    margin-top: 1.5rem;
    font-size: 3rem;
    font-weight: normal;
    line-height: 1.2; }
    @media (--mobile) {
      .s-item-box__txt {
        font-size: 2.8rem;
        line-height: 1.3; } }
  .s-item-box__side {
    display: flex;
    margin-top: 2.8rem; }
    @media (--mobile) {
      .s-item-box__side {
        display: block;
        margin-top: 2rem; } }
    .s-item-box__side span {
      position: relative;
      padding-left: 3.2rem;
      font-size: 1.4rem;
      color: #7638a0; }
      @media (--mobile) {
        .s-item-box__side span {
          display: block; }
          .s-item-box__side span:nth-of-type(n + 2) {
            margin-top: 1rem; } }
      .s-item-box__side span::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 2.3rem;
        height: 1.6rem;
        content: "";
        background: url(/common/images/common/check-icon.png) no-repeat center center/contain;
        transform: translateY(-50%); }
      @media (--large) {
        .s-item-box__side span:nth-of-type(n + 2) {
          margin-left: 1rem; } }
  .s-item-box__btn {
    margin-top: 4rem; }
    @media (--mobile) {
      .s-item-box__btn {
        width: 21rem;
        margin-right: auto;
        margin-left: auto; } }

.s-bg {
  position: relative;
  z-index: 3;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #ddecf8; }
  @media (--mobile) {
    .s-bg {
      padding-top: 3rem;
      padding-bottom: 5rem; } }
  .s-bg::after {
    position: absolute;
    top: 0.3rem;
    left: 0;
    width: 100vw;
    height: 21.5rem;
    pointer-events: none;
    content: "";
    background: url(/common/images/common/ebris02.png) no-repeat top center/contain;
    transform: translateY(-100%); }
    @media (--mobile) {
      .s-bg::after {
        top: 0.9rem;
        height: 6.2rem; } }
  .s-bg::before {
    position: absolute;
    bottom: 0.1rem;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 16rem;
    pointer-events: none;
    content: "";
    background: url(/common/images/common/ebris03.png) no-repeat bottom center/contain;
    transform: translateY(100%); }
    @media (--mobile) {
      .s-bg::before {
        height: 4.3rem; } }

.c-backUp {
  position: relative;
  z-index: 2;
  padding-top: 10rem;
  margin-top: -10rem; }
  .c-backUp__ttl {
    font-family: "Montserrat", sans-serif;
    font-size: 8rem; }
    @media (--mobile) {
      .c-backUp__ttl {
        font-size: 5rem; } }
  .c-backUp__txt {
    position: relative;
    font-size: 4.4rem;
    font-weight: bold; }
    .c-backUp__txt::after {
      position: absolute;
      top: -17rem;
      right: 0;
      width: 12.2rem;
      height: 19.3rem;
      content: "";
      background: url(/common/images/common/char15.png) no-repeat center center/contain; }
      @media (--mobile) {
        .c-backUp__txt::after {
          width: 10.2rem;
          height: 17.3rem; } }
    @media (--mobile) {
      .c-backUp__txt {
        margin-top: 3rem;
        font-size: 2.8rem; } }
  .c-backUp__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -1rem;
    margin-top: 0.6rem; }
    @media (--large) {
      .c-backUp__list {
        margin: 0 -3rem; } }
    @media (--mobile) {
      .c-backUp__list {
        margin: 0 -1rem; } }
  .c-backUp__el a {
    transition: 0.3s opacity; }
    .c-backUp__el a:hover {
      opacity: 0.6; }
  @media (--large) {
    .c-backUp__el {
      margin: 3rem 3rem 0; } }
  @media (--mobile) {
    .c-backUp__el {
      margin: 3rem 1rem 0; } }
  .c-backUp__el:nth-of-type(1) {
    width: 17rem; }
    @media (--mobile) {
      .c-backUp__el:nth-of-type(1) {
        width: 15rem; } }
  .c-backUp__el:nth-of-type(2) {
    width: 19.7rem; }
    @media (--mobile) {
      .c-backUp__el:nth-of-type(2) {
        width: 13rem; } }

.s-news {
  margin-top: 12rem;
  overflow: hidden; }
  @media (--mobile) {
    .s-news {
      margin-top: 10rem; } }
  @media (--large) {
    .s-news__inner {
      padding-left: 10rem; } }
  @media (--mobile) {
    .s-news__inner {
      padding: 0 3rem; } }
  .s-news__ttl {
    font-family: "Montserrat", sans-serif;
    font-size: 4.4rem; }
    @media (--mobile) {
      .s-news__ttl {
        font-size: 4.2rem; } }
  .s-news__list {
    margin-top: 3.6rem; }
  .s-news__el {
    position: relative;
    padding: 4rem;
    background: rgb(255 255 255 / 50%);
    border-radius: 1rem; }
    @media (--mobile) {
      .s-news__el {
        padding: 3rem; } }
    .s-news__el::before {
      position: absolute;
      top: -3rem;
      left: 50%;
      display: block;
      width: 6rem;
      height: 6rem;
      content: "";
      background: url(/common/images/common/news-icon.png) no-repeat center center/contain;
      transform: translateX(-50%); }
      @media (--mobile) {
        .s-news__el::before {
          width: 5rem;
          height: 5rem; } }
  .s-news__img {
    width: 100%; }
  .s-news__subTtl {
    min-height: 10rem;
    margin-top: 2.4rem;
    font-size: 2.2rem;
    font-weight: lighter; }
    @media (--mobile) {
      .s-news__subTtl {
        min-height: 8rem; } }
    .s-news__subTtl a {
      color: #7638a0;
      transition: 0.3s opacity; }
      .s-news__subTtl a:hover {
        opacity: 0.6; }
  .s-news__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 6rem;
    margin-top: 2rem;
    background: #fff;
    border-radius: 1rem; }
    .s-news__logo.black {
      background-color: #000; }
      .s-news__logo.black img {
        width: 6.8rem; }
    .s-news__logo img {
      display: block;
      width: 13rem;
      margin-right: auto;
      margin-left: auto; }
  .s-news__btn {
    margin-top: 2rem; }

.s-support {
  margin-top: 14rem; }
  @media (--mobile) {
    .s-support {
      margin-top: 8rem; } }
  .s-support__ttl {
    font-family: "Montserrat", sans-serif;
    font-size: 4.4rem; }
    @media (--mobile) {
      .s-support__ttl {
        font-size: 4.2rem; } }
  .s-support__list {
    display: flex;
    margin-top: 6rem; }
    @media (--mobile) {
      .s-support__list {
        flex-wrap: wrap;
        justify-content: center;
        margin: 4rem -3rem 0; } }
  .s-support__el {
    height: 12.4rem; }
    @media (--large) {
      .s-support__el:nth-of-type(n + 2) {
        margin-left: 13.3rem; } }
    @media (--mobile) {
      .s-support__el {
        height: 9rem;
        margin: 0 1rem; } }
    .s-support__el img {
      max-width: inherit;
      height: 100%; }

.s-ourTeam {
  position: relative;
  z-index: 2;
  padding-top: 30rem; }
  @media (--mobile) {
    .s-ourTeam {
      padding-top: 13rem; } }
  .s-ourTeam__ttl {
    font-family: "Montserrat", sans-serif;
    font-size: 6.6rem;
    text-align: center; }
    @media (--mobile) {
      .s-ourTeam__ttl {
        font-size: 4.2rem; } }
  .s-ourTeam__list {
    display: flex;
    justify-content: center;
    margin-top: 10rem; }
    @media (--mobile) {
      .s-ourTeam__list {
        flex-wrap: wrap;
        margin-top: 5rem; } }
  .s-ourTeam__el {
    text-align: center; }
    @media (--large) {
      .s-ourTeam__el:nth-of-type(n + 2) {
        margin-left: 8rem; } }
    @media (--mobile) {
      .s-ourTeam__el {
        width: 46%; }
        .s-ourTeam__el:nth-of-type(2) {
          margin-left: 8%; }
        .s-ourTeam__el:nth-of-type(3) {
          margin-top: 2rem; } }
  .s-ourTeam__img {
    margin-right: auto;
    margin-left: auto;
    transition: 0.3s; }
    @media (--large) {
      .s-ourTeam__img {
        width: 20rem; } }
    @media (--mobile) {
      .s-ourTeam__img {
        width: 13rem;
        margin-right: auto;
        margin-left: auto; } }
  .s-ourTeam a:hover .s-ourTeam__img {
    transform: scale(1.05); }
  .s-ourTeam__subTtl {
    margin-top: 2.1rem; }
    .s-ourTeam__subTtl span {
      position: relative;
      font-size: 1.8rem; }
      @media (--large) {
        .s-ourTeam__subTtl span {
          padding-right: 3.4rem; } }
      @media (--mobile) {
        .s-ourTeam__subTtl span {
          display: inline-block; } }
      @media (--large) {
        .s-ourTeam__subTtl span::after {
          position: absolute;
          top: 50%;
          right: 0;
          width: 2.6rem;
          height: 2.6rem;
          content: "";
          background: url(/common/images/common/insta-icon.png) no-repeat center center/contain;
          transform: translateY(-50%); } }
  .s-ourTeam__job {
    margin-top: 1rem;
    font-size: 1.3rem;
    color: #c498d5; }
    @media (--mobile) {
      .s-ourTeam__job span {
        position: relative;
        display: inline-block;
        padding-left: 2.5rem; }
        .s-ourTeam__job span::after {
          position: absolute;
          top: 50%;
          left: 0;
          width: 2rem;
          height: 2rem;
          content: "";
          background: url(/common/images/common/insta-icon.png) no-repeat center center/contain;
          transform: translateY(-50%); } }

.s-community {
  position: relative;
  z-index: 3;
  margin-top: 24.8rem; }
  @media (--mobile) {
    .s-community {
      margin-top: 10rem; } }
  .s-community__ttl {
    font-family: "Montserrat", sans-serif;
    font-size: 6.6rem;
    text-align: center; }
    @media (--mobile) {
      .s-community__ttl {
        font-size: 4.4rem; } }
  @media (--large) {
    .s-community__list {
      display: flex;
      justify-content: center;
      margin-top: 8.4rem; } }
  @media (--mobile) {
    .s-community__list {
      margin-top: 5rem; } }
  .s-community__el {
    position: relative;
    background: #fff;
    border-radius: 0.8rem; }
    @media (--large) {
      .s-community__el {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27.4rem;
        height: 32rem;
        text-align: center; } }
    @media (--large) {
      .s-community__el:nth-of-type(n + 2) {
        margin-left: 8rem; } }
    @media (--mobile) {
      .s-community__el:nth-of-type(n + 2) {
        margin-top: 2rem; } }
    .s-community__el a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
    .s-community__el::after {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      width: calc(100% + 0.8rem);
      height: calc(100% + 0.8rem);
      content: "";
      background: #000;
      background: linear-gradient(to left, #8ec9ff, #a9bbe3, #c498d5, #ff63c4);
      filter: blur(0);
      border-radius: 1rem;
      transition: 0.3s filter;
      transform: translate(-50%, -50%); }
    .s-community__el:hover::after {
      filter: blur(1.5rem); }
  @media (--mobile) {
    .s-community__link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5rem 0;
      color: #000; } }
  .s-community__img {
    max-width: inherit; }
    @media (--mobile) {
      .s-community__img {
        text-align: center; } }
    .s-community__img img {
      height: 4.3rem; }
      @media (--mobile) {
        .s-community__img img {
          height: 3.2rem; } }
    .s-community__img figcaption {
      margin-top: 2rem;
      font-size: 2rem; }
      @media (--mobile) {
        .s-community__img figcaption {
          margin-top: 1rem;
          font-size: 1.8rem; } }
  .s-community__logo {
    width: 15.6rem;
    margin-top: 1.8rem;
    margin-right: auto;
    margin-left: auto; }
    @media (--mobile) {
      .s-community__logo {
        margin: inherit;
        margin-top: 0;
        margin-left: 3rem; } }

.s-pageTop {
  position: relative;
  z-index: 3;
  margin-top: 15.1rem;
  text-align: center; }
  @media (--mobile) {
    .s-pageTop {
      padding-right: 3rem;
      margin-top: 5rem;
      text-align: right; } }
  .s-pageTop img {
    width: 22.7rem; }
    @media (--mobile) {
      .s-pageTop img {
        width: 13rem; } }
  .s-pageTop a {
    display: inline-block;
    transition: 0.3s; }
    @media (--large) {
      .s-pageTop a:hover {
        transform: scale(1.1); } }

.s-b-marquee02 {
  position: relative;
  z-index: 3;
  display: flex;
  width: 250vw;
  margin-top: 8rem; }
  @media (--mobile) {
    .s-b-marquee02 {
      width: 500vw;
      margin-top: 5rem; } }
  .s-b-marquee02 svg {
    padding-left: 3rem; }
